@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900&display=swap');



@font-face {
  font-family: 'Nunito Sans', sans-serif;
  src: url("../fonts/NunitoSans-Bold.ttf")format("truetype"),
       url("../fonts/NunitoSans-Regular.ttf") format("truetype");
}

* , html{
  font-family: 'Nunito Sans', sans-serif;
  }
.navbar {
  width: 100%;
  min-height: 6vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #0b96f3;
  z-index: 2;
  transition: all 0.5s ease-in;
}
.trans {
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  transition-delay: 1s;
}

/* //IMPORTANT */
.scrolled {
  position: fixed;
  top: 0;
  left: 0;
}
.logo img {
  width: 50px;
}

/* bg video */
.Container {
  position: relative;
  overflow: hidden;
  display: flex;
  direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
 
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
height: 100%;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 30%;
  display: flex;
  align-items: center;
}
/* scroll */

App {
  text-align: center;
  height: 5000px;
}

.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.grad {
  background-image: linear-gradient(to right, rgb(16, 3, 34), #868687);
}
.read-or-hide{
  color: rgb(52, 52, 53);
  cursor: pointer;
}
.submit{
  background-color: rgb(64, 204, 64);
}